import {  ReactElement } from 'react';

import '../../assets/styles/components/mainBoard.scss';

const MainBoard = (): ReactElement => {

  return (
    <div>
      <span className="hello">Hello World!</span>
    </div>
  );
};

export default MainBoard;
