import React, { createContext, useContext, useReducer, useMemo, Dispatch, ReactNode } from "react";
import {IGlobalState} from "../interfaces/IGlobalState";

interface Action {
  type: keyof IGlobalState;
  payload: any;
}



const initialState: IGlobalState = {
  isLoading: false,
};



const GlobalContext = createContext<{state: IGlobalState, dispatch: Dispatch<Action>}>({
  state: initialState,
  dispatch: (): void => {}
});

function reducer(state: IGlobalState, action: Action): IGlobalState {
  return { ...state, [action.type]: action.payload };
}

export function useGlobalContext() {
  return useContext(GlobalContext);
}

interface ProviderProps {
  children: ReactNode;
}

export const ContextProvider: React.FC<ProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
      <GlobalContext.Provider
          value={useMemo(() => ({ state, dispatch }), [state])}
      >
        {children}
      </GlobalContext.Provider>
  );
};