import { Layouts } from '../components/layouts/layout';
import MainBoard from '../components/home/MainBoard';

import '../assets/styles/pages/home.scss';

export const HomePage = () => {
  return (
    <Layouts>
        <div>
            <p className="test">Some red text</p>
        </div>
      <MainBoard />
    </Layouts>
  );
};
