import {ReactElement, ReactNode} from "react"
import './layout.scss'

interface LayoutProps {
  children: ReactNode;
}

export const Layouts = ({ children }: LayoutProps) : ReactElement => {
  return (
    <div className="layout-wrapper">
      {children}
    </div>
  )
}